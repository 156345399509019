'use client';

import React, { useState } from 'react';
import appLogo from '@/assets/icons/app-logo.svg';
import orangeLogo from '@/assets/icons/orange-logo-small.svg';
import Link from 'next/link';
import style from './navbar.module.scss';
import Button from '../../button';
import Image from 'next/image';
const NavBar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const toggleMenu = () => {
    setOpenMenu(prevState => !prevState);
  };
  return <div className={style.navWrapper} data-sentry-component="NavBar" data-sentry-source-file="index.jsx">
      <div className={style.navInner}>
        <div>
          <Link href={'/'} data-sentry-element="Link" data-sentry-source-file="index.jsx">
            <Image src={appLogo} alt="app-logo" className={style.appLogo} data-sentry-element="Image" data-sentry-source-file="index.jsx" />
          </Link>
        </div>
        <div className={style.navLinks}>
          {routes?.map(ele => <Link href={ele.path} key={ele.path}>
              <span className={style.navLabels}>{ele.label}</span>
            </Link>)}
        </div>
        <div className={style.navBtns}>
          <Link href="/contact" data-sentry-element="Link" data-sentry-source-file="index.jsx">
            <Button className={style.contactBtn} label={'CONTACT US'} data-sentry-element="Button" data-sentry-source-file="index.jsx" />
          </Link>
          <Link href="/login" data-sentry-element="Link" data-sentry-source-file="index.jsx">
            <Button className={style.loginBtn} label={'LOGIN'} data-sentry-element="Button" data-sentry-source-file="index.jsx" />
          </Link>
        </div>
      </div>
      <div className={style.navMobile}>
        <Image src={orangeLogo} data-sentry-element="Image" data-sentry-source-file="index.jsx" />
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={toggleMenu} data-sentry-element="svg" data-sentry-source-file="index.jsx">
          <path d="M3 12H21M3 6H21M3 18H21" stroke="#254956" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="index.jsx" />
        </svg>
      </div>
      {openMenu && <div className={style.navMobileMenu}>
          <div className={style.navLinksMobile}>
            {routes?.map(ele => <Link href={ele.path} key={ele.path}>
                <span className={style.navLabels}>{ele.label}</span>
              </Link>)}
          </div>
          <div className={style.navBtnsMobile}>
            <Link href="/contact">
              <Button className={style.contactBtn} label={'CONTACT US'} />
            </Link>
            <Link href="/login">
              <Button className={style.loginBtn} label={'LOGIN'} />
            </Link>
          </div>
        </div>}
      {openMenu && <div className={style.backdrop} onClick={toggleMenu}></div>}
    </div>;
};
export default NavBar;
const routes = [{
  path: '/',
  label: 'COACHING SERVICES'
}, {
  path: '/courses',
  label: 'COURSES'
}, {
  path: '/',
  label: 'PARTNERS'
}, {
  path: '/blog',
  label: 'BLOG'
}, {
  path: '/about-us',
  label: 'ABOUT'
}];